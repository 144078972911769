import React from 'react';
import logo from './logo.png';
import './App.css';
import GoogleStore from './googleStore.png'
import AppleStore from './appleStore.png'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div style={{ margin: '10px', display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
          <span style={{fontSize: '3em', fontWeight: 600 }}>poopee</span>
          <img src={logo} style={{width: '3em', pointerEvents: 'none', marginLeft: '10px'}} alt="logo" />
        </div>
        <div style={{margin: '10px'}}>
          <img onClick={()=>{
            window.location.href = "https://apps.apple.com/us/app/poopee/id6447502621";
          }} style={{ margin: '5px', width: '150px', cursor: 'pointer'}}src={AppleStore} alt='appleStore' />
          <img onClick={()=>{
            alert("Coming soon!")
          }}style={{ margin: '5px', width: '150px', cursor: 'pointer'}}src={GoogleStore} alt='googleStore' />
        </div>
      </header>
    </div>
  );
}

export default App;
